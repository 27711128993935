import { TokenAddressMap } from '../state/lists/hooks'
export enum SupportedChainId {
  MAINNET = 1,
  ROPSTEN = 3,
  RINKEBY = 4,
  GOERLI = 5,
  KOVAN = 42,
  ARBITRUM_KOVAN = 144545313136048,
  ARBITRUM_ONE = 42161,
  BSCMAIN = 56,
  BSCTEST = 97,
  HECOMAIN = 128,
  HECOTEST = 256,
  MATIC = 137,
  MATICTEST = 80001,
}

export function combineMaps(map1: TokenAddressMap, map2: TokenAddressMap): TokenAddressMap {
  return {
    [1]: { ...map1[1], ...map2[1] },
    [3]: { ...map1[3], ...map2[3] },
    [4]: { ...map1[4], ...map2[4] },
    [5]: { ...map1[5], ...map2[5] },
    [42]: { ...map1[42], ...map2[42] },
    [56]: { ...map1[56], ...map2[56] },
    [97]: { ...map1[97], ...map2[97] },
    [128]: { ...map1[128], ...map2[128] },
    [256]: { ...map1[256], ...map2[256] },
    [137]: { ...map1[137], ...map2[137] },
    [80001]: { ...map1[80001], ...map2[80001] },
  }
}

import { constructSameAddressMap } from './constructSameAddressMap'
import { SupportedChainId } from './chains'
import EthereumLogo from '../assets/images/ethereum-logo.png'
import BSCLogo from '../assets/images/BSC-logo.png'
import HECOLogo from '../assets/images/HECO-logo.png'

export const ETHERSCAN_PREFIXES: { [chainId: number]: string } = {
  [SupportedChainId.MAINNET]: '',
  [SupportedChainId.ROPSTEN]: 'ropsten.etherscan.io',
  [SupportedChainId.RINKEBY]: 'rinkeby.etherscan.io',
  [SupportedChainId.GOERLI]: 'goerli.etherscan.io',
  [SupportedChainId.KOVAN]: 'kovan.etherscan.io',
  [SupportedChainId.BSCMAIN]: 'bscscan.com',
  [SupportedChainId.BSCTEST]: 'testnet.bscscan.com',
  [SupportedChainId.HECOMAIN]: 'hecoinfo.com',
  [SupportedChainId.HECOTEST]: 'testnet.hecoinfo.com',
}

type AddressMap = { [chainId: number]: string }

export enum PermitType {
  AMOUNT = 1,
  ALLOWED = 2,
}
export interface PermitInfo {
  type: PermitType
  name: string
  // version is optional, and if omitted, will not be included in the domain
  version?: string
}
interface multiChainPermitInfo {
  [chainId: number]: PermitInfo
}

interface Detail {
  name: string
  symbol: string
}

export const NATIVE_LOGO: { [chainId in SupportedChainId | number]: string } = {
  [SupportedChainId.MAINNET]: EthereumLogo,
  [SupportedChainId.RINKEBY]: EthereumLogo,
  [SupportedChainId.ROPSTEN]: EthereumLogo,
  [SupportedChainId.GOERLI]: EthereumLogo,
  [SupportedChainId.KOVAN]: EthereumLogo,
  [SupportedChainId.ARBITRUM_KOVAN]: EthereumLogo,
  [SupportedChainId.ARBITRUM_ONE]: EthereumLogo,
  [SupportedChainId.BSCMAIN]: BSCLogo,
  [SupportedChainId.BSCTEST]: BSCLogo,
  [SupportedChainId.HECOMAIN]: HECOLogo,
  [SupportedChainId.HECOTEST]: HECOLogo,
}

export const NATIVE_TOKEN_NAME: { [chainId in SupportedChainId | number]: Detail } = {
  [SupportedChainId.MAINNET]: { name: 'Ether', symbol: 'ETH' },
  [SupportedChainId.RINKEBY]: { name: 'Ether', symbol: 'ETH' },
  [SupportedChainId.ROPSTEN]: { name: 'Ether', symbol: 'ETH' },
  [SupportedChainId.GOERLI]: { name: 'Ether', symbol: 'ETH' },
  [SupportedChainId.KOVAN]: { name: 'Ether', symbol: 'ETH' },
  [SupportedChainId.ARBITRUM_KOVAN]: { name: 'Ether', symbol: 'ETH' },
  [SupportedChainId.ARBITRUM_ONE]: { name: 'Ether', symbol: 'ETH' },
  [SupportedChainId.BSCMAIN]: { name: 'Binance', symbol: 'BNB' },
  [SupportedChainId.BSCTEST]: { name: 'Binance', symbol: 'BNB' },
  [SupportedChainId.HECOMAIN]: { name: 'Huobi Token', symbol: 'HT' },
  [SupportedChainId.HECOTEST]: { name: 'Huobi Token', symbol: 'HT' },
}
export const NETWORK_LABELS: { [chainId in SupportedChainId | number]: string } = {
  [SupportedChainId.MAINNET]: 'Mainnet',
  [SupportedChainId.RINKEBY]: 'Rinkeby',
  [SupportedChainId.ROPSTEN]: 'Ropsten',
  [SupportedChainId.GOERLI]: 'Görli',
  [SupportedChainId.KOVAN]: 'Kovan',
  [SupportedChainId.ARBITRUM_KOVAN]: 'kArbitrum',
  [SupportedChainId.ARBITRUM_ONE]: 'Arbitrum One',
  [SupportedChainId.BSCMAIN]: `BSC Main`,
  [SupportedChainId.BSCTEST]: `BSC Test`,
  [SupportedChainId.HECOMAIN]: `HECO Main`,
  [SupportedChainId.HECOTEST]: `HECO Test`,
}

export const REMOVE_V2_LIQUIDITY_PERMIT_INFO: multiChainPermitInfo = {
  [SupportedChainId.BSCMAIN]: {
    version: '1',
    name: 'Uniswap V2',
    type: PermitType.AMOUNT,
  },
  [SupportedChainId.BSCTEST]: {
    version: '1',
    name: 'Uniswap V2',
    type: PermitType.AMOUNT,
  },
}
export const V2_INIT_CODE_HASH: AddressMap = {
  ...constructSameAddressMap('0xf0031dd82891f301a2e51848e7c54273e0ff229cfcb86e75c3bfb085dc7a95dc', false),
}
export const V3_POOL_INIT_CODE_HASH: AddressMap = {
  ...constructSameAddressMap('0xd70958ebfff5a99884d37fb1ba60083c838125942b9ab042cf8d49a7929bb9d0', false),
}
export const UNI_ADDRESS: AddressMap = constructSameAddressMap('0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984', false)
export const MULTICALL2_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0x95963A808b4273d0A2b9b5234cD57b5095071729', false),
  [SupportedChainId.ARBITRUM_KOVAN]: '0xc80e33a6f02cf08557a0ca3d94d1474d73f64bc1',
  [SupportedChainId.ARBITRUM_ONE]: '0x021CeAC7e681dBCE9b5039d2535ED97590eB395c',
}
export const V2_FACTORY_ADDRESSES: AddressMap = constructSameAddressMap(
  '0x99926d145D79567d2F07007e2095A3c1570e6D5e',
  false
)
export const V2_ROUTER_ADDRESS: AddressMap = constructSameAddressMap(
  '0x0872B14C6fc1dF9b9070ECbEd22547627d012A58',
  false
)
export const GOVERNANCE_ADDRESSES: AddressMap[] = [
  constructSameAddressMap('0x5e4be8Bc9637f0EAA1A755019e06A68ce081D58F', false),
  {
    [SupportedChainId.MAINNET]: '0xC4e172459f1E7939D522503B81AFAaC1014CE6F6',
  },
]
export const TIMELOCK_ADDRESS: AddressMap = constructSameAddressMap('0x1a9C8182C09F50C8318d769245beA52c32BE35BC', false)
export const MERKLE_DISTRIBUTOR_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x090D4613473dEE047c3f2706764f49E0821D256e',
}
export const ARGENT_WALLET_DETECTOR_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0xeca4B0bDBf7c55E9b7925919d03CbF8Dc82537E8',
}
export const V3_CORE_FACTORY_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0x2e07978f213A9823333dC5121D79379e7b4dfb15', true),
  [SupportedChainId.ARBITRUM_KOVAN]: '0xf594DEF7751440197879149f46E98b334E6DF1fa',
}
export const QUOTER_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0x856E06a26e32A03932f7a408bA1B296a212239c7', true),
  [SupportedChainId.ARBITRUM_KOVAN]: '0xAC06b88FA9adB7584A659b190F37F085352cB783',
}
export const NONFUNGIBLE_POSITION_MANAGER_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0x5B16C5D70f9baBb0102eD658156cCF285600431c', true),
  [SupportedChainId.ARBITRUM_KOVAN]: '0x9E1498aE1F508E86462e8A0F213CF385A6622464',
}
export const ENS_REGISTRAR_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  [SupportedChainId.ROPSTEN]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  [SupportedChainId.GOERLI]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  [SupportedChainId.RINKEBY]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
}
export const SOCKS_CONTROLLER_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0x65770b5283117639760beA3F867b69b3697a91dd',
}
export const SWAP_ROUTER_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0x0b2e287309A8728A01C6Ec8683349E524e103b25', true),
  [SupportedChainId.ARBITRUM_KOVAN]: '0x6ae2DE23F2BE35B3921ba15DA52e4b173667dCb9',
}
export const V3_MIGRATOR_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0x96474d57D767c9A1941e60bbE7F73Bd286dDF9A3', true),
}
